import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c("HeadbarPageComponent", { attrs: { title: "Emolumentos" } }),
      _c(
        VCard,
        { staticClass: "mt-2 pa-3" },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { md: "2", lg: "2" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.years,
                      label: "Ano",
                      "item-text": "name",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.yearSelected,
                      callback: function($$v) {
                        _vm.yearSelected = $$v
                      },
                      expression: "yearSelected"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { md: "2", lg: "2" } },
                [
                  _c(VSelect, {
                    attrs: {
                      solo: "",
                      clearable: "",
                      items: _vm.listaUfs,
                      label: "UF",
                      "item-text": "name",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.uf,
                      callback: function($$v) {
                        _vm.uf = $$v
                      },
                      expression: "uf"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { md: "2", lg: "2" } },
                [
                  _c(VTextField, {
                    attrs: { prefix: "R$", clearable: "", label: "" },
                    on: {
                      input: function($event) {
                        _vm.vl_saldo_protesto = _vm.formatDinheiroString($event)
                      }
                    },
                    model: {
                      value: _vm.vl_saldo_protesto,
                      callback: function($$v) {
                        _vm.vl_saldo_protesto = $$v
                      },
                      expression: "vl_saldo_protesto"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.projetarProxAno()
                        }
                      }
                    },
                    [_vm._v(" Projetar para Prox Ano ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "primary white--text d-flex justify-center",
                      attrs: {
                        disabled: _vm.emolumentos.length === 0,
                        medium: ""
                      },
                      model: {
                        value: _vm.botaoRelatorioXls,
                        callback: function($$v) {
                          _vm.botaoRelatorioXls = $$v
                        },
                        expression: "botaoRelatorioXls"
                      }
                    },
                    [
                      _c(
                        "download-excel",
                        {
                          staticClass: "ma-auto",
                          attrs: {
                            data: _vm.emolumentos,
                            name: "Emolumentos-" + _vm.yearSelected + ".xls",
                            worksheet: "Emolumentos",
                            type: "xls"
                          }
                        },
                        [
                          _c(VIcon, { staticClass: "ma-3" }, [
                            _vm._v("mdi-microsoft-excel")
                          ]),
                          _vm._v("Baixar XLS Resultados ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.getEmolumentos()
                        }
                      }
                    },
                    [_vm._v(" Pesquisar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        { staticClass: "mt-2 pa-3" },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogNewEmolumento = true
                        }
                      }
                    },
                    [_c(VIcon, [_vm._v("mdi-plus")]), _vm._v(" Adicionar ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                [
                  _c(VDataTable, {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.emolumentos,
                      loading: _vm.loading,
                      options: _vm.options,
                      "footer-props": {
                        itemsPerPageOptions: [100, 200, 300, -1],
                        itemsPerPageText: "Itens por página:"
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.vl_inicial",
                        fn: function(props) {
                          return [
                            _c(
                              VEditDialog,
                              {
                                attrs: {
                                  "return-value": props.item.vl_inicial
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_inicial",
                                      $event
                                    )
                                  },
                                  "update:return-value": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_inicial",
                                      $event
                                    )
                                  },
                                  save: function($event) {
                                    return _vm.save(props.item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function() {
                                        return [
                                          _c(VTextField, {
                                            attrs: {
                                              prefix: "R$",
                                              label: "Edit",
                                              "single-line": ""
                                            },
                                            on: {
                                              input: function($event) {
                                                props.item.vl_inicial = _vm.formatDinheiroString(
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: props.item.vl_inicial,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "vl_inicial",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.vl_inicial"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.mascaraMoeda(props.item.vl_inicial)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.vl_custas",
                        fn: function(props) {
                          return [
                            _c(
                              VEditDialog,
                              {
                                attrs: { "return-value": props.item.vl_custas },
                                on: {
                                  "update:returnValue": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_custas",
                                      $event
                                    )
                                  },
                                  "update:return-value": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_custas",
                                      $event
                                    )
                                  },
                                  save: function($event) {
                                    return _vm.save(props.item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function() {
                                        return [
                                          _c(VTextField, {
                                            attrs: {
                                              prefix: "R$",
                                              label: "Edit",
                                              "single-line": ""
                                            },
                                            on: {
                                              input: function($event) {
                                                props.item.vl_custas = _vm.formatDinheiroString(
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: props.item.vl_custas,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "vl_custas",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.vl_custas"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.mascaraMoeda(props.item.vl_custas)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.vl_final",
                        fn: function(props) {
                          return [
                            _c(
                              VEditDialog,
                              {
                                attrs: { "return-value": props.item.vl_final },
                                on: {
                                  "update:returnValue": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_final",
                                      $event
                                    )
                                  },
                                  "update:return-value": function($event) {
                                    return _vm.$set(
                                      props.item,
                                      "vl_final",
                                      $event
                                    )
                                  },
                                  save: function($event) {
                                    return _vm.save(props.item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function() {
                                        return [
                                          _c(VTextField, {
                                            attrs: {
                                              prefix: "R$",
                                              label: "Edit",
                                              "single-line": ""
                                            },
                                            on: {
                                              input: function($event) {
                                                props.item.vl_final = _vm.formatDinheiroString(
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value: props.item.vl_final,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "vl_final",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.vl_final"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.mascaraMoeda(props.item.vl_final)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.obs",
                        fn: function(props) {
                          return [
                            _c(
                              VEditDialog,
                              {
                                attrs: { "return-value": props.item.obs },
                                on: {
                                  "update:returnValue": function($event) {
                                    return _vm.$set(props.item, "obs", $event)
                                  },
                                  "update:return-value": function($event) {
                                    return _vm.$set(props.item, "obs", $event)
                                  },
                                  save: function($event) {
                                    return _vm.save(props.item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function() {
                                        return [
                                          _c(VTextField, {
                                            attrs: {
                                              label: "Edit",
                                              "single-line": ""
                                            },
                                            model: {
                                              value: props.item.obs,
                                              callback: function($$v) {
                                                _vm.$set(props.item, "obs", $$v)
                                              },
                                              expression: "props.item.obs"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_vm._v(" " + _vm._s(props.item.obs) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.snack
                    ? _c("Snackbar", {
                        attrs: {
                          mostrarSnackbar: _vm.snack,
                          corSnackbar: _vm.snackColor,
                          mensagemSnackbar: _vm.snackText
                        },
                        on: {
                          fecharSnackbar: function($event) {
                            _vm.snack = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.dialogNewEmolumento,
                callback: function($$v) {
                  _vm.dialogNewEmolumento = $$v
                },
                expression: "dialogNewEmolumento"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, [_vm._v("Adicionar emolumento")]),
                  _c(
                    VCardText,
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            [
                              _c(VSelect, {
                                attrs: {
                                  solo: "",
                                  clearable: "",
                                  items: _vm.listaUfs,
                                  label: "UF",
                                  "item-text": "name",
                                  "item-value": "value"
                                },
                                model: {
                                  value: _vm.newEmolumento.cd_uf,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newEmolumento, "cd_uf", $$v)
                                  },
                                  expression: "newEmolumento.cd_uf"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.years,
                                  label: "Ano",
                                  "item-text": "name",
                                  "item-value": "value"
                                },
                                model: {
                                  value: _vm.newEmolumento.ano_referencia,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newEmolumento,
                                      "ano_referencia",
                                      $$v
                                    )
                                  },
                                  expression: "newEmolumento.ano_referencia"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(VTextField, {
                        attrs: { prefix: "R$", label: "Valor Inicial" },
                        on: {
                          input: function($event) {
                            _vm.newEmolumento.vl_inicial = _vm.formatDinheiroString(
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.newEmolumento.vl_inicial,
                          callback: function($$v) {
                            _vm.$set(_vm.newEmolumento, "vl_inicial", $$v)
                          },
                          expression: "newEmolumento.vl_inicial"
                        }
                      }),
                      _c(VTextField, {
                        attrs: { prefix: "R$", label: "Valor Final" },
                        on: {
                          input: function($event) {
                            _vm.newEmolumento.vl_final = _vm.formatDinheiroString(
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.newEmolumento.vl_final,
                          callback: function($$v) {
                            _vm.$set(_vm.newEmolumento, "vl_final", $$v)
                          },
                          expression: "newEmolumento.vl_final"
                        }
                      }),
                      _c(VTextField, {
                        attrs: { prefix: "R$", label: "Valor Custas" },
                        on: {
                          input: function($event) {
                            _vm.newEmolumento.vl_custas = _vm.formatDinheiroString(
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.newEmolumento.vl_custas,
                          callback: function($$v) {
                            _vm.$set(_vm.newEmolumento, "vl_custas", $$v)
                          },
                          expression: "newEmolumento.vl_custas"
                        }
                      }),
                      _c(VTextField, {
                        attrs: { label: "Observação" },
                        model: {
                          value: _vm.newEmolumento.obs,
                          callback: function($$v) {
                            _vm.$set(_vm.newEmolumento, "obs", $$v)
                          },
                          expression: "newEmolumento.obs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(
                        VBtn,
                        {
                          on: {
                            click: function($event) {
                              return _vm.createEmolumento()
                            }
                          }
                        },
                        [_vm._v("Salvar")]
                      ),
                      _c(
                        VBtn,
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogNewEmolumento = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }