import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { attrs: { align: "left", justify: "left" } },
    [
      _c(
        VCardText,
        { staticClass: "pa-3 font-weight-light white black--text" },
        [
          _c(VIcon, { staticClass: "primary--text lighten-2 pa-2" }, [
            _vm._v(" mdi-sync ")
          ]),
          _vm._v(_vm._s(_vm.title) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }